export const multilingual = {
    english: {
        layout: {
            language: 'Language',
            welcome: 'Welcome',
            copyright: `Copyright EnerShare Consortium @${new Date().getFullYear()} All rights reserved`,
            eu_msg: 'Co-funded by the Horizon 2020 Framework Programme of the European Union Under grant agreement No 101069831',
            homepage: 'Homepage',
            menuItem1: 'Building Retrofitting',
            menuItem2: 'Photovoltaic Installation',
            menuItem3: 'Training Playground',
            menuItem4: 'Data Marketplace',
            signIn: 'Sign In',
            signUp: 'Sign Up',
            singOut: 'Sign Out',
            platformTitle: 'Artificial Intelligence for Energy Efficiency',
        },
        homepage: {
            title1: 'Building Retrofitting',
            subtitle1: 'Select your desired energy class and receive recommendations about the appropriate construction works.',
            title2: 'Photovoltaic Installation',
            subtitle2: 'Select your desired install PV power and receive future insights on energy, cost, and CO2 emissions reduction.',
            title3: 'Model Training',
            subtitle3: 'Train AI4EF models on your custom datasets, for personalized energy efficiency insights.',
        },
        buildingRetrofitting: {
            breadcrumb1: 'Homepage',
            breadcrumb2: 'Building Retroffiting',
            building_parameters: 'Building Parameters',
            building_total_area: 'Building Total Area (m²)',
            monthly_energy_consumption_before: 'Monthly Energy Consumption before (kWh/m²)',
            above_ground_floors: 'Above-ground Floors',
            initial_energy_class: 'Initial Energy Class',
            energy_class_after: 'Energy Class After',
            energy_class_info: 'Energy class "after" must be the same or better than "before"',
            reset: 'RESET',
            calculate: 'CALCULATE',
            edit: 'EDIT',
            additional_info_title: 'Building Consumption / Energy Class information',
            additional_info_subtitle: 'Use this table to identify your building\'s energy class based on the consumption.',
            additional_info_msg: `All the numbers represent heating consumption measured in kWh/m<sup>2</sup>`,
            table_column1: 'Energy Efficiency class of building',
            table_column2: `Heating area (50m<sup>2</sup> to 120m<sup>2</sup>`,
            table_column3: `Heating area (120m<sup>2</sup> to 250m<sup>2</sup>)`,
            table_column4: `Heating area (over 250m<sup>2</sup>)`,
            error: 'Something went wrong! Please check your input and try again.',
            requiredField: 'This field is required.',
            recommendations: 'Recommendations'
        },
        photovoltaicInstallation: {
            breadcrumb1: 'Homepage',
            breadcrumb2: 'Photovoltaic Installation',
            pv_parameters: 'Photovoltaic Installation Parameters',
            avg_monthly_consumption_before: 'Average monthly consumption before (kWh/month)',
            avg_electricity_price: 'Average electricity price (€/kwh)',
            renewable_installation_cost: 'Renewable installation cost (€)',
            renewable_energy_generated: 'Average amount of energy generated by the renewable equipment (MWh/year)',
            current_inverter_set_power: 'Current Inverter Set Power (kW)',
            planned_inverter_set_power: 'Planned Inverter Set Power (kW)',
            region: 'Region',
            instructions: 'Leave this blank (or 0) if you don’t know the value and the AI algorithm will make an estimation based on your input.',
            reset: 'RESET',
            calculate: 'CALCULATE',
            edit: 'EDIT',
            requiredField: 'This field is required.',
            error: 'Something went wrong! Please check your input because it may have some inconsistencies! Otherwise, please try again in a bit!',
            forecasts: 'Forecasts'
        },
        myProfile: {
            breadcrumb1: 'Homepage',
            breadcrumb2: 'User Profile',
            current_user: 'Currently logged in user',
            clickDetails: 'Click for details',
            username: 'Username',
            roles: 'Roles',
            first_name: 'First Name',
            last_name: 'Last Name',
            expand: 'Click to expand',
            noRoles: 'No roles assigned',
            role_singular: 'role',
            role_plural: 'roles'
        },
    },
    latvian: {
        layout: {
            language: 'Valoda',
            welcome: 'Laipni lūdzam',
            copyright: `Autortiesības EnerShare konsorcijs @${new Date().getFullYear()} Visas tiesības aizsargātas`,
            eu_msg: 'Līdzfinansē Eiropas Savienības pamatprogramma "Apvārsnis 2020" saskaņā ar dotāciju nolīgumu Nr. 101069831',
            homepage: 'Sākumlapa',
            menuItem1: 'Ieguldījumu veikšana ēkā',
            menuItem2: 'Saules paneļu sistēmas uzstādīšana',
            menuItem3: 'Darba lapa',
            menuItem4: 'Datu platforma',
            signIn: 'Pieslēgties',
            signUp: 'Reģistrēties',
            singOut: 'Atslēgties',
            platformTitle: 'Energoefektivitātes rīks',
        },
        homepage: {
            title1: 'Ieguldījumu veikšana ēkā',
            subtitle1: 'Izvēlieties sev vēlamo energoefektivitātes klasi un saņemiet ieteikumus par nepieciešamajiem veicamajiem darbiem ēkā',
            title2: 'Saules paneļu sistēmas uzstādīšana',
            subtitle2: 'Izvēlieties saules paneļu sistēmas jaudu un saņemiet ieskatu par enerģiju, izmaksu un CO2 emisiju samazinājumu',
            title3: 'Darba lapa',
            subtitle3: 'Iegūstiet personalizētu energoefektivitātes ieskatu',
        },
        buildingRetrofitting: {
            breadcrumb1: 'Sākumlapa',
            breadcrumb2: 'Ieguldījumu veikšana ēkā',
            building_parameters: 'Ēkas parametri',
            building_total_area: 'Ēkas kopējā platība (m²)',
            monthly_energy_consumption_before: 'Siltumenerģijas patēriņš mēnesī pirms (kWh/m²)',
            above_ground_floors: 'Virszemes stāvi',
            initial_energy_class: 'Sākotnējā ēkas energoefektivitātes klase',
            energy_class_after: 'Vēlamā ēkas energoefektivitātes klase',
            energy_class_info: 'Vēlamai ēkas energoefektivitātes klasei jābūt augstākai par sākotnējo',
            reset: 'ATJAUNOT',
            calculate: 'APRĒĶINĀT',
            edit: 'LABOT',
            additional_info_title: 'Siltumenerģijas patēriņš ēkā / informācija par ēkas energoefektivitātes klasi.',
            additional_info_subtitle: 'Izmantojiet šo tabulu, lai noteiktu ēkas energoefektivitātes klasi, balstoties uz siltumenerģijas patēriņu.',
            additional_info_msg: 'Visi skaitļi norāda apkures patēriņu (kWh/m²).',
            table_column1: 'Ēkas energoefektivitātes klase',
            table_column2: 'Apkurināmā platība (50m² līdz 120m²)',
            table_column3: 'Apkurināmā platība (120m² līdz 250m²)',
            table_column4: 'Apkurināmā platība (virs 250m²)',
            error: 'Kļūdaini aizpildīta informācija! Lūdzu, pārbaudiet ievadīto informāciju un mēģiniet vēlreiz.',
            requiredField: 'Obligāti aizpildāms lauks.',
            recommendations: 'Ieteikumi'
        },
        photovoltaicInstallation: {
            breadcrumb1: 'Sākumlapa',
            breadcrumb2: 'Saules paneļu sistēmas uzstādīšana',
            pv_parameters: 'Saules paneļu sistēmas parametri',
            avg_monthly_consumption_before: 'Vidējais elektroenerģijas patēriņš mēnesī (kWh/mēnesī)',
            avg_electricity_price: 'Vidējā elektrības cena (EUR/kWh)',
            renewable_installation_cost: 'Saules paneļu sistēmas izmaksas (€)',
            renewable_energy_generated: 'Iekārtas saražotais elektroenerģijas apjoms (MWh/gadā)',
            current_inverter_set_power: 'Līdz šim esošā invertora iestatītā jauda (kW)',
            planned_inverter_set_power: 'Plānotā uzstādāmā invertora iestatītā jauda (kW)',
            region: 'Reģions',
            instructions: 'Atstājiet šo lauku tukšu vai ievadiet 0, ja neziniet vērtību - rīks veiks novērtējumu, pamatojoties uz pieejamo informāciju.',
            reset: 'ATJAUNOT',
            calculate: 'APRĒĶINĀT',
            edit: 'LABOT',
            requiredField: 'Obligāti aizpildāms lauks.',
            error: 'Kļūdaini aizpildīta informācija! Lūdzu, pārbaudiet ievadīto informāciju un mēģiniet vēlreiz.',
            forecasts: 'Prognozes'
        },
        myProfile: {
            breadcrumb1: 'Sākumlapa',
            breadcrumb2: 'Lietotāja profils',
            current_user: 'Pašreiz pieteicies lietotājs',
            clickDetails: 'Noklikšķiniet, lai skatītu detalizētu informāciju',
            username: 'Lietotājvārds',
            roles: 'Lomas',
            first_name: 'Vārds',
            last_name: 'Uzvārds',
            expand: 'Noklikšķiniet, lai izvērstu',
            noRoles: 'Nav piešķirtas lomas',
            role_singular: 'loma',
            role_plural: 'lomas'
        },
    }
}