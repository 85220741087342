import React, {useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useTheme} from '@mui/material/styles';
import axios from 'axios';
import {transformToHumanReadable} from "../utils";
import {useKeycloak} from "@react-keycloak/web";
import {LanguageContext} from "../context/LanguageContext";
import {multilingual} from "../multilingual";

import {
    Container, Accordion, AccordionSummary, AccordionDetails,
    Button, Typography, Grid, TextField, FormControl,
    InputLabel, Select, Divider, MenuItem, Card, Box, Stack, Alert, IconButton,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import Breadcrumb from '../components/layout/Breadcrumb';
import Loading from "../components/layout/Loading";

function PhotovoltaicInstallation() {
    const theme = useTheme();
    const {keycloak, initialized} = useKeycloak();
    const [allowed, setAllowed] = useState(false);
    const {language} = useContext(LanguageContext);
    const dictionary = language === 'en' ? multilingual.english.photovoltaicInstallation : multilingual.latvian.photovoltaicInstallation;

    const breadcrumbs = [
        <Link className={'breadcrumbLink'} key="1" to="/">
            {dictionary.breadcrumb1}
        </Link>,
        <Typography
            underline="hover"
            key="2"
            color="primary"
            fontSize={'20px'}
            fontWeight={600}
        >
            {dictionary.breadcrumb2}
        </Typography>,
    ];

    useEffect(() => {
        if (initialized) {
            if (keycloak.authenticated !== true) {
                keycloak.login();
            } else {
                setAllowed(true);
            }
        }
    }, [initialized]);

    const initialFormState = {
        average_monthly_electricity_consumption_before: '',
        average_electricity_price: '',
        renewable_installation_cost: '',
        renewable_energy_generated: '',
        current_inverter_set_power: '',
        planned_inverter_set_power: '',
        region: '',
    };

    const [formErrors, setFormErrors] = useState({
        average_monthly_electricity_consumption_before: false,
        average_electricity_price: false,
        renewable_installation_cost: false,
        renewable_energy_generated: false,
        current_inverter_set_power: false,
        planned_inverter_set_power: false,
        region: false,
    });

    const [formData, setFormData] = useState(initialFormState);
    const [error, setError] = useState(false);
    const [accordionOpen, setAccordionOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [forecasts, setForecasts] = useState([]);
    const [touched, setTouched] = useState(false);
    const [storedResponse, setStoredResponse] = useState([])

    const handleFormChange = (event) => {
        const {name, value, type} = event.target;
        setFormData({...formData, [name]: type === 'number' ? Number(value) : value});
        setFormErrors({...formErrors, [name]: false});
    };

    const handleReset = () => {
        setForecasts([]);
        setError(false);
        setFormData(initialFormState);
        setFormErrors({
            average_monthly_electricity_consumption_before: false,
            average_electricity_price: false,
            renewable_installation_cost: false,
            renewable_energy_generated: false,
            current_inverter_set_power: false,
            planned_inverter_set_power: false,
            region: false,
        });
        setTouched(false);
    };

    const handleSave = () => {
        setTouched(true); // Mark the form as touched

        const submissionData = {
            ...formData,
            renewable_energy_generated: formData.renewable_energy_generated === 0 ? '' : formData.renewable_energy_generated,
        };

        const isFormValid = Object.entries(formData).reduce(
            (isValid, [name, value]) => {
                if (
                    formData.renewable_energy_generated !== '' &&
                    formData.renewable_energy_generated !== 0 &&
                    (name === 'current_inverter_set_power' || name === 'planned_inverter_set_power' || name === 'region')
                ) {
                    return isValid; // Skip validation if renewable_energy_generated is provided
                }

                if (name !== 'renewable_energy_generated') {
                    if (typeof value === 'string') {
                        const isEmpty = value.trim() === '';
                        setFormErrors((prevErrors) => ({
                            ...prevErrors,
                            [name]: isEmpty,
                        }));
                        return isValid && !isEmpty;
                    }
                    return isValid;
                }
                return isValid;
            },
            true
        );

        if (isFormValid) {
            setLoading(true);
            setError(false);
            setForecasts([]);

            axios.post('/service_2/inference', submissionData)
                .then(response => {
                    setLoading(false);
                    setStoredResponse(response.data)
                    language === 'en' ? setForecasts(response.data.english) : setForecasts(response.data.latvian)
                })
                .catch(error => {
                    setLoading(false);
                    setError(true);
                    console.log(error);
                });
        }
    };

    useEffect(() => {
        if (
            storedResponse &&
            typeof storedResponse === 'object' &&
            storedResponse.english &&
            storedResponse.latvian
        ) {
            setForecasts(language === 'en' ? storedResponse.english : storedResponse.latvian);
        }
    }, [language, storedResponse]);

    return (
        <>
            <Breadcrumb breadcrumbs={breadcrumbs} welcome_msg={''}/>
            {allowed && <Container maxWidth="xl" sx={{my: 3}}>
                <Accordion
                    expanded={accordionOpen}
                    onChange={() => setAccordionOpen(!accordionOpen)}
                >
                    <AccordionSummary>
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid item xs={12} sm={accordionOpen ? 6 : 2} style={{textAlign: 'left'}}>
                                <Typography variant="h6" component="div" style={{
                                    flex: '2',
                                    wordWrap: 'break-word',
                                    color: '#9966ff',
                                    fontWeight: 'bold'
                                }}>
                                    {dictionary.pv_parameters}
                                </Typography>
                            </Grid>
                            {accordionOpen ? (
                                <Button color="primary" onClick={() => setAccordionOpen(!accordionOpen)}>
                                    <ExpandMoreIcon sx={{color: '#9966ff'}}/>
                                </Button>
                            ) : (
                                <>
                                    {language === 'en' && Object.entries(formData).map(([key, value]) => (
                                        <Grid item xs={12} sm={1} key={key} style={{textAlign: 'center'}}>
                                            <Typography variant="body2">
                                                <strong>{transformToHumanReadable(key)}</strong>
                                                <br/> {value || '-'}
                                            </Typography>
                                        </Grid>
                                    ))}
                                    <Grid item xs={12} sm={2} style={{textAlign: 'center'}}>
                                        <Button
                                            color="primary"
                                            size={'large'}
                                            onClick={() => setAccordionOpen(!accordionOpen)}
                                            variant="outlined"
                                            sx={{borderColor: '#9966ff', color: '#9966ff'}}
                                        >
                                            {accordionOpen ?
                                                <ExpandMoreIcon sx={{color: '#9966ff'}}/> : dictionary.edit}
                                        </Button>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="average_monthly_electricity_consumption_before"
                                    label={dictionary.avg_monthly_consumption_before}
                                    fullWidth
                                    value={formData.average_monthly_electricity_consumption_before}
                                    onChange={handleFormChange}
                                    required
                                    error={touched && formErrors.average_monthly_electricity_consumption_before}
                                    helperText={
                                        touched && formErrors.average_monthly_electricity_consumption_before && dictionary.requiredField
                                    }
                                    type="number"
                                    inputProps={{inputMode: 'numeric', min: 0}}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="average_electricity_price"
                                    label={dictionary.avg_electricity_price}
                                    fullWidth
                                    value={formData.average_electricity_price}
                                    onChange={handleFormChange}
                                    required
                                    error={touched && formErrors.average_electricity_price}
                                    helperText={
                                        touched && formErrors.average_electricity_price && dictionary.requiredField
                                    }
                                    type="number"
                                    inputProps={{inputMode: 'numeric', min: 0}}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="renewable_installation_cost"
                                    label={dictionary.renewable_installation_cost}
                                    fullWidth
                                    value={formData.renewable_installation_cost}
                                    onChange={handleFormChange}
                                    required
                                    error={touched && formErrors.renewable_installation_cost}
                                    helperText={
                                        touched && formErrors.renewable_installation_cost && dictionary.requiredField
                                    }
                                    type="number"
                                    inputProps={{inputMode: 'numeric', min: 0}}
                                />
                            </Grid>

                            <Divider style={{width: '90%'}} sx={{mt: 3, mb: 2, mx: 'auto'}}/>

                            <Grid item xs={12}>
                                <TextField
                                    name="renewable_energy_generated"
                                    label={dictionary.renewable_energy_generated}
                                    fullWidth
                                    value={formData.renewable_energy_generated}
                                    onChange={handleFormChange}
                                    type="number"
                                    error={touched && formErrors.renewable_energy_generated}
                                    helperText={
                                        touched && formErrors.renewable_energy_generated && dictionary.requiredField
                                    }
                                    inputProps={{inputMode: 'numeric', min: 0}}
                                />

                                <Typography variant={'body2'}
                                            sx={{
                                                marginRight: 'auto',
                                                alignItems: 'center',
                                                color: theme.palette.primary.main,
                                            }}>
                                    <IconButton size="small" color="primary">
                                        <InfoOutlinedIcon/>
                                    </IconButton>
                                    {dictionary.instructions}
                                </Typography>
                            </Grid>

                            {(formData.renewable_energy_generated === '' || formData.renewable_energy_generated === 0) && <>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="current_inverter_set_power"
                                        label={dictionary.current_inverter_set_power}
                                        fullWidth
                                        value={formData.current_inverter_set_power}
                                        onChange={handleFormChange}
                                        required={formData.renewable_energy_generated === '' || formData.renewable_energy_generated === 0}
                                        disabled={formData.renewable_energy_generated !== '' && formData.renewable_energy_generated !== 0}
                                        error={touched && formErrors.current_inverter_set_power && !(formData.renewable_energy_generated !== '' && formData.renewable_energy_generated !== 0)}
                                        helperText={
                                            touched && formErrors.current_inverter_set_power && !(formData.renewable_energy_generated !== '' && formData.renewable_energy_generated !== 0) &&
                                            dictionary.requiredField
                                        }
                                        type="number"
                                        inputProps={{inputMode: 'numeric', min: 0}}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="planned_inverter_set_power"
                                        label={dictionary.planned_inverter_set_power}
                                        fullWidth
                                        value={formData.planned_inverter_set_power}
                                        onChange={handleFormChange}
                                        required={formData.renewable_energy_generated === '' || formData.renewable_energy_generated === 0}
                                        disabled={formData.renewable_energy_generated !== '' && formData.renewable_energy_generated !== 0}
                                        error={touched && formErrors.planned_inverter_set_power && !(formData.renewable_energy_generated !== '' && formData.renewable_energy_generated !== 0)}
                                        helperText={
                                            touched && formErrors.planned_inverter_set_power && !(formData.renewable_energy_generated !== '' && formData.renewable_energy_generated !== 0) &&
                                            dictionary.requiredField
                                        }
                                        type="number"
                                        inputProps={{inputMode: 'numeric', min: 0}}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth
                                                 required={formData.renewable_energy_generated === '' || formData.renewable_energy_generated === 0}
                                                 disabled={formData.renewable_energy_generated !== '' && formData.renewable_energy_generated !== 0}>
                                        <InputLabel
                                            error={touched && formErrors.region && !(formData.renewable_energy_generated !== '' && formData.renewable_energy_generated !== 0)}>{dictionary.region}</InputLabel>
                                        <Select
                                            name="region"
                                            label="region"
                                            value={formData.region}
                                            onChange={handleFormChange}
                                            error={touched && formErrors.region && !(formData.renewable_energy_generated !== '' && formData.renewable_energy_generated !== 0)}
                                        >
                                            <MenuItem value="Kurzeme">Kurzeme</MenuItem>
                                            <MenuItem value="Rīga">Riga</MenuItem>
                                            <MenuItem value="Zemgale">Zemgale</MenuItem>
                                            <MenuItem value="Latgale">Latgale</MenuItem>
                                            <MenuItem value="Vidzeme">Vidzeme</MenuItem>
                                        </Select>
                                        {touched && formErrors.region && !(formData.renewable_energy_generated !== '' && formData.renewable_energy_generated !== 0) && (
                                            <Typography variant="caption" color="error" sx={{ml: 2, mt: '2px'}}>
                                                {dictionary.requiredField}
                                            </Typography>
                                        )}
                                    </FormControl>
                                </Grid>
                            </>}

                        </Grid>
                        <Grid container justifyContent="flex-end" mt={3} mb={1}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleReset}
                                sx={{borderColor: '#9966ff', color: '#9966ff', mx: 2}}
                            >
                                <RestartAltIcon/>
                                {dictionary.reset}
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSave}
                                sx={{
                                    backgroundColor: '#9966ff',
                                    borderColor: '#9966ff',
                                    color: 'white',
                                }}
                            >
                                <InfoOutlinedIcon sx={{mr: '4px'}}/>
                                {dictionary.calculate}
                            </Button>
                        </Grid>
                    </AccordionDetails>
                </Accordion>

                {loading && <Loading/>}

                {error &&
                    <Stack sx={{width: '100%'}} spacing={2}>
                        <Alert severity="error">{dictionary.error}</Alert>
                    </Stack>
                }

                {!loading && forecasts.length > 0 && (
                    <>
                        <Typography
                            variant={'h4'}
                            sx={{
                                color: theme.palette.primary.main,
                                mt: 5,
                                fontWeight: 'bold',
                                borderBottom: '1px solid #9966ff',
                            }}
                        >
                            {dictionary.forecasts}
                        </Typography>
                        <Grid container spacing={2} my={1}>
                            {forecasts
                                .filter((forecast) => forecast.value != 'False')
                                .map((forecast) => (
                                    <Grid
                                        item
                                        key={forecast.id}
                                        xs={12}
                                        md={4}
                                    >
                                        <Card
                                            sx={{
                                                backgroundColor: 'white',
                                                height: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    flex: 1,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <Typography
                                                    variant="h6"
                                                    align={'center'}
                                                    component="div"
                                                    fontWeight={'bold'}
                                                    sx={{
                                                        marginTop: 0,
                                                        alignSelf: 'flex-start',
                                                        padding: '8px',
                                                        mx: 'auto',
                                                        color: theme.palette.primary.main,
                                                    }}
                                                >
                                                    {forecast.title}
                                                </Typography>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <img
                                                        src={`/images/service2/id${forecast.id}.jpg`}
                                                        alt={forecast.title}
                                                        style={{
                                                            marginTop: '20px',
                                                            maxHeight: '140px',
                                                            width: 'auto',
                                                        }}
                                                    />
                                                    <Typography
                                                        variant="body2"
                                                        color="text.secondary"
                                                        sx={{p: 2}}
                                                    >
                                                        {forecast.description}
                                                    </Typography>
                                                </div>
                                            </div>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    flexDirection: 'column',
                                                    marginBottom: '8px',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <Typography variant={'h3'} fontWeight={'bold'}
                                                                color={theme.palette.primary.main}>{forecast.value}</Typography>
                                                    <Typography variant={'body2'}>{forecast.unit}</Typography>
                                                </div>
                                            </Box>
                                        </Card>
                                    </Grid>
                                ))}
                        </Grid>
                    </>
                )}
            </Container>}
        </>
    );
}

export default PhotovoltaicInstallation;
