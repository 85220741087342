import React, {useContext} from 'react';
import {useTheme} from '@mui/material/styles';
import {LanguageContext} from "../../context/LanguageContext";
import {multilingual} from "../../multilingual";

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Typography
} from '@mui/material';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

function BuildingEnergyClassTable() {
    const theme = useTheme();
    const {language} = useContext(LanguageContext);
    const dictionary = language === 'en' ? multilingual.english.buildingRetrofitting : multilingual.latvian.buildingRetrofitting;

    return (
        <>
            <Typography variant={'body2'}
                        sx={{
                            mt: '5',
                            marginRight: 'auto',
                            alignItems: 'center',
                            color: theme.palette.primary.main
                        }}>
                <IconButton size="small" color="primary">
                    <InfoOutlinedIcon/>
                </IconButton>
                {dictionary.additional_info_subtitle}
            </Typography>

            <TableContainer sx={{border: '1px solid #ccc'}}>
                <Table>
                    <TableHead>
                        <TableRow sx={{backgroundColor: theme.palette.primary.main}}>
                            <TableCell
                                sx={{ color: 'white', textAlign: 'center' }}
                                dangerouslySetInnerHTML={{ __html: dictionary.table_column1 }}
                            />
                            <TableCell
                                sx={{ color: 'white', textAlign: 'center' }}
                                dangerouslySetInnerHTML={{ __html: dictionary.table_column2 }}
                            />

                            <TableCell
                                sx={{ color: 'white', textAlign: 'center' }}
                                dangerouslySetInnerHTML={{ __html: dictionary.table_column3 }}
                            />
                            <TableCell
                                sx={{ color: 'white', textAlign: 'center' }}
                                dangerouslySetInnerHTML={{ __html: dictionary.table_column4 }}
                            />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell sx={{textAlign: 'center'}}>A+</TableCell>
                            <TableCell sx={{textAlign: 'center'}}>&le; 35</TableCell>
                            <TableCell sx={{textAlign: 'center'}}>&le; 35</TableCell>
                            <TableCell sx={{textAlign: 'center'}}>&le; 30</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{textAlign: 'center'}}>A</TableCell>
                            <TableCell sx={{textAlign: 'center'}}>&le; 60</TableCell>
                            <TableCell sx={{textAlign: 'center'}}>&le; 50</TableCell>
                            <TableCell sx={{textAlign: 'center'}}>&le; 40</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{textAlign: 'center'}}>B</TableCell>
                            <TableCell sx={{textAlign: 'center'}}>&le; 75</TableCell>
                            <TableCell sx={{textAlign: 'center'}}>&le; 65</TableCell>
                            <TableCell sx={{textAlign: 'center'}}>&le; 60</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{textAlign: 'center'}}>C</TableCell>
                            <TableCell sx={{textAlign: 'center'}}>&le; 95</TableCell>
                            <TableCell sx={{textAlign: 'center'}}>&le; 90</TableCell>
                            <TableCell sx={{textAlign: 'center'}}>&le; 80</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{textAlign: 'center'}}>D</TableCell>
                            <TableCell sx={{textAlign: 'center'}}>&le; 150</TableCell>
                            <TableCell sx={{textAlign: 'center'}}>&le; 130</TableCell>
                            <TableCell sx={{textAlign: 'center'}}>&le; 100</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{textAlign: 'center'}}>E</TableCell>
                            <TableCell sx={{textAlign: 'center'}}>&le; 180</TableCell>
                            <TableCell sx={{textAlign: 'center'}}>&le; 150</TableCell>
                            <TableCell sx={{textAlign: 'center'}}>&le; 125</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{textAlign: 'center'}}>F</TableCell>
                            <TableCell sx={{textAlign: 'center'}}>&gt; 180</TableCell>
                            <TableCell sx={{textAlign: 'center'}}>&gt; 150</TableCell>
                            <TableCell sx={{textAlign: 'center'}}>&gt; 125</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default BuildingEnergyClassTable;
