import SolarPowerIcon from '@mui/icons-material/SolarPower';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';

export const servicesHomepageEnglish = [
    {
        'id': 'investment_planning',
        'title': 'Building Retrofitting',
        'description': 'Select your desired energy class and receive recommendations about the appropriate construction works.',
        'icon': <EnergySavingsLeafIcon style={{color: 'white', fontSize: '80px'}}/>,
        'image': '/images/investment_planning.jpg',
    },
    {
        'id': 'photovoltaic_installation',
        'title': 'Photovoltaic Installation',
        'description': 'Select your desired install PV power and receive future insights on energy, cost, and CO2 emissions reduction.',
        'icon': <SolarPowerIcon style={{color: 'white', fontSize: '80px'}}/>,
        'image': '/images/photovoltaic_installation.jpg',
    },
    {
        'id': 'model_training',
        'title': 'Model Training',
        'description': 'Train AI4EF models on your custom datasets, for personalized energy efficiency insights',
        'icon': <ModelTrainingIcon style={{color: 'white', fontSize: '80px'}}/>,
        'image': '/images/model_training.png',
    },
]

export const servicesHomepageLatvian = [
    {
        'id': 'investment_planning',
        'title': 'Ieguldījumu veikšana ēkā',
        'description': 'Izvēlieties sev vēlamo energoefektivitātes klasi un saņemiet ieteikumus par nepieciešamajiem veicamajiem darbiem ēkā.',
        'icon': <EnergySavingsLeafIcon style={{color: 'white', fontSize: '80px'}} />,
        'image': '/images/investment_planning.jpg',
    },
    {
        'id': 'photovoltaic_installation',
        'title': 'Saules paneļu sistēmas uzstādīšana',
        'description': 'Izvēlieties saules paneļu sistēmas jaudu un saņemiet ieskatu par enerģiju, izmaksu un CO2 emisiju samazinājumu.',
        'icon': <SolarPowerIcon style={{color: 'white', fontSize: '80px'}} />,
        'image': '/images/photovoltaic_installation.jpg',
    },
    {
        'id': 'model_training',
        'title': 'Darba lapa',
        'description': 'Iegūstiet personalizētu energoefektivitātes ieskatu.',
        'icon': <ModelTrainingIcon style={{color: 'white', fontSize: '80px'}} />,
        'image': '/images/model_training.png',
    },
];
