import React, {useEffect, useState, useContext} from 'react';
import {useTheme} from '@mui/material/styles';
import {Link} from "react-router-dom";
import axios from 'axios';
import {transformToHumanReadable} from "../utils";
import {useKeycloak} from "@react-keycloak/web";
import {LanguageContext} from "../context/LanguageContext";
import {multilingual} from "../multilingual";

import {
    Container,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    Typography,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Card,
    Modal,
    IconButton,
    Alert, Stack
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';


import Breadcrumb from "../components/layout/Breadcrumb";
import Loading from "../components/layout/Loading";
import BuildingEnergyClass from "../components/investmentPlanning/BuildingEnergyClass";

function BuildingRetrofitting() {
    const {keycloak, initialized} = useKeycloak();
    const [allowed, setAllowed] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [noRecommendations, setNoRecommendations] = useState(false);
    const {language} = useContext(LanguageContext);
    const dictionary = language === 'en' ? multilingual.english.buildingRetrofitting : multilingual.latvian.buildingRetrofitting;

    const breadcrumbs = [
        <Link className={'breadcrumbLink'} key="1" to="/">
            {dictionary.breadcrumb1}
        </Link>,
        <Typography
            underline="hover"
            key="2"
            color="primary"
            fontSize={'20px'}
            fontWeight={600}>
            {dictionary.breadcrumb2}
        </Typography>,
    ];

    useEffect(() => {
        if (initialized) {
            if (keycloak.authenticated !== true) {
                keycloak.login();
            } else {
                setAllowed(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialized]);

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const theme = useTheme();

    const initialFormState = {
        building_total_area: '',
        energy_consumption_before: '',
        above_ground_floors: '',
        initial_energy_class: '',
        energy_class_after: '',
    };

    const initialFormErrors = {
        building_total_area: false,
        energy_consumption_before: false,
        above_ground_floors: false,
        initial_energy_class: false,
        energy_class_after: false,
    };

    const [formData, setFormData] = useState(initialFormState);
    const [formErrors, setFormErrors] = useState(initialFormErrors);
    const [accordionOpen, setAccordionOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [recommendations, setRecommendations] = useState([]);
    const [error, setError] = useState(false);
    const [storedResponse, setStoredResponse] = useState([])

    const energyClasses = ['A', 'B', 'C', 'D', 'E', 'F']; // Ordered classes

    const handleFormChange = (event) => {
        const {name, value} = event.target;

        if (name === 'initial_energy_class') {
            // Reset `energy_class_after` when `initial_energy_class` changes
            setFormData((prev) => ({
                ...prev,
                initial_energy_class: value,
                energy_class_after: '', // Reset after class
            }));
        } else if (name === 'energy_class_after') {
            // Ensure `energy_class_after` is the same or better than `initial_energy_class`
            const beforeIndex = energyClasses.indexOf(formData.initial_energy_class);
            const afterIndex = energyClasses.indexOf(value);

            if (afterIndex > beforeIndex) {
                setFormErrors((prevErrors) => ({
                    ...prevErrors,
                    energy_class_after: true, // Mark as error
                }));
            } else {
                setFormErrors((prevErrors) => ({
                    ...prevErrors,
                    energy_class_after: false, // Clear error
                }));
                setFormData((prev) => ({
                    ...prev,
                    energy_class_after: value,
                }));
            }
        } else {
            setFormData((prev) => ({
                ...prev,
                [name]: value,
            }));
        }

        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: false,
        }));
    };

    const handleReset = () => {
        setError(false);
        setRecommendations([]);
        setFormData(initialFormState);
        setFormErrors(initialFormErrors);
    };

    const handleSave = () => {
        const isFormValid = Object.entries(formData).reduce(
            (isValid, [name, value]) => {
                if (typeof value === 'string') {
                    const isEmpty = value.trim() === '';
                    setFormErrors((prevErrors) => ({
                        ...prevErrors,
                        [name]: isEmpty,
                    }));
                    return isValid && !isEmpty;
                }
                return isValid;
            },
            true
        );

        if (isFormValid) {
            setError(false);
            setRecommendations([]);
            setLoading(true);

            // Save logic here
            axios.post('/service_1/inference', formData)
                .then(response => {
                    const allFalse = response.data.english.every(item => item.value === "False");
                    setNoRecommendations(allFalse);
                    setLoading(false);
                    setStoredResponse(response.data)

                    language === 'en' ? setRecommendations(response.data.english) : setRecommendations(response.data.latvian)
                })
                .catch(error => {
                    setLoading(false);
                    setError(true);
                    console.log(error);
                });
        }
    };

    useEffect(() => {
        if (
            storedResponse &&
            typeof storedResponse === 'object' &&
            storedResponse.english &&
            storedResponse.latvian
        ) {
            setRecommendations(language === 'en' ? storedResponse.english : storedResponse.latvian);
        }
    }, [language, storedResponse]);

    const calculateColumnCount = recommendationCount => {
        if (recommendationCount <= 4) {
            return 4;
        } else {
            return recommendationCount % 3 === 0 ? 4 : 3;
        }
    };

    return (
        <>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div className="modalContainer">
                    <div className="modalContent" style={{ position: 'relative' }}>
                        {/* X Icon Button */}
                        <IconButton
                            onClick={handleCloseModal}
                            sx={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                                color: theme.palette.grey[500],
                            }}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>

                        <Typography
                            id="modal-title"
                            variant="h6"
                            sx={{
                                marginRight: 'auto',
                                alignItems: 'center',
                                color: theme.palette.primary.main,
                            }}
                        >
                            {dictionary.additional_info_title}
                        </Typography>
                        <Typography id="modal-description" variant="body1" sx={{ mb: 2 }}>
                            {dictionary.additional_info_subtitle}
                        </Typography>

                        <BuildingEnergyClass />
                    </div>
                </div>
            </Modal>


            <Breadcrumb breadcrumbs={breadcrumbs} welcome_msg={''}/>
            {allowed && <Container maxWidth="xl" sx={{my: 3}}>
                <Accordion
                    expanded={accordionOpen}
                    onChange={() => setAccordionOpen(!accordionOpen)}
                >
                    <AccordionSummary>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item xs={12} sm={accordionOpen ? 6 : 2} style={{textAlign: 'left'}}>
                                <Typography variant="h6" component="div" style={{
                                    flex: '2',
                                    wordWrap: 'break-word',
                                    color: '#9966ff',
                                    fontWeight: 'bold'
                                }}>
                                    {dictionary.building_parameters}
                                </Typography>
                            </Grid>
                            {accordionOpen ? (
                                <Button color="primary" onClick={() => setAccordionOpen(!accordionOpen)}>
                                    <ExpandMoreIcon
                                        sx={{color: '#9966ff'}}/> {/* Display the chevron-up icon when open */}
                                </Button>
                            ) : (
                                <>
                                    {language === 'en' && Object.entries(formData).map(([key, value]) => (
                                        <Grid item xs={12} sm={1} key={key} style={{textAlign: 'center'}}>
                                            <Typography variant="body2">
                                                <strong>{transformToHumanReadable(key)}</strong>
                                                <br/> {value || '-'}
                                            </Typography>
                                        </Grid>
                                    ))}
                                    <Grid item xs={12} sm={2} style={{textAlign: 'center'}}>
                                        <Button
                                            color="primary"
                                            size={'large'}
                                            onClick={() => setAccordionOpen(!accordionOpen)}
                                            variant="outlined" // Use outlined button
                                            sx={{borderColor: '#9966ff', color: '#9966ff'}}
                                        >
                                            {accordionOpen ?
                                                <ExpandMoreIcon sx={{color: '#9966ff'}}/> : dictionary.edit}
                                        </Button>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Grid container spacing={2} sx={{alignItems: "center"}}>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    name="building_total_area"
                                    label={dictionary.building_total_area}
                                    fullWidth
                                    value={formData.building_total_area}
                                    onChange={handleFormChange}
                                    required
                                    error={formErrors.building_total_area}
                                    helperText={formErrors.building_total_area && dictionary.requiredField}
                                    type="number"
                                    inputProps={{
                                        inputMode: 'numeric',
                                        min: 0,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField
                                    name="energy_consumption_before"
                                    label={dictionary.monthly_energy_consumption_before}
                                    fullWidth
                                    value={formData.energy_consumption_before}
                                    onChange={handleFormChange}
                                    required
                                    error={formErrors.energy_consumption_before}
                                    helperText={formErrors.energy_consumption_before && dictionary.requiredField}
                                    type="number"
                                    inputProps={{
                                        inputMode: 'numeric',
                                        min: 0,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth required>
                                    <InputLabel
                                        error={formErrors.above_ground_floors}>{dictionary.above_ground_floors}</InputLabel>
                                    <Select
                                        name="above_ground_floors"
                                        label={dictionary.above_ground_floors}
                                        value={formData.above_ground_floors}
                                        onChange={handleFormChange}
                                        required
                                        error={formErrors.above_ground_floors}
                                    >
                                        <MenuItem value={1}>1</MenuItem>
                                        <MenuItem value={2}>2</MenuItem>
                                        <MenuItem value={3}>3</MenuItem>
                                    </Select>
                                    {formErrors.above_ground_floors && (
                                        <Typography variant="caption" color="error" sx={{ml: 2, mt: '2px'}}>
                                            {dictionary.requiredField}
                                        </Typography>
                                    )}
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth required>
                                    <InputLabel
                                        error={formErrors.initial_energy_class}>{dictionary.initial_energy_class}</InputLabel>
                                    <Select
                                        name="initial_energy_class"
                                        label={dictionary.initial_energy_class}
                                        value={formData.initial_energy_class}
                                        onChange={handleFormChange}
                                        required
                                        error={formErrors.initial_energy_class}
                                    >
                                        {energyClasses.map((cls) => (
                                            <MenuItem key={cls} value={cls}>{cls}</MenuItem>
                                        ))}
                                    </Select>
                                    {formErrors.initial_energy_class && (
                                        <Typography variant="caption" color="error" sx={{ml: 2, mt: '2px'}}>
                                            {dictionary.requiredField}
                                        </Typography>
                                    )}
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth required>
                                    <InputLabel
                                        error={formErrors.energy_class_after}>{dictionary.energy_class_after}</InputLabel>
                                    <Select
                                        name="energy_class_after"
                                        label={dictionary.energy_class_after}
                                        value={formData.energy_class_after}
                                        onChange={handleFormChange}
                                        required
                                        error={formErrors.energy_class_after}
                                    >
                                        {energyClasses
                                            .filter(
                                                (cls) =>
                                                    energyClasses.indexOf(cls) <=
                                                    energyClasses.indexOf(formData.initial_energy_class)
                                            )
                                            .map((cls) => (
                                                <MenuItem key={cls} value={cls}>{cls}</MenuItem>
                                            ))}
                                    </Select>
                                    {formErrors.energy_class_after && (
                                        <Typography variant="caption" color="error" sx={{ml: 2, mt: '2px'}}>
                                            {dictionary.requiredField}
                                        </Typography>
                                    )}
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container justifyContent="flex-end" alignItems="center" mt={3} mb={1}>
                            <Typography variant={'h6'}
                                        sx={{
                                            marginRight: 'auto',
                                            alignItems: 'center',
                                            color: theme.palette.primary.main
                                        }}>
                                {dictionary.additional_info_title}
                                <IconButton size="small" color="primary" onClick={handleOpenModal}>
                                    <InfoOutlinedIcon/>
                                </IconButton>
                            </Typography>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleReset}
                                sx={{borderColor: '#9966ff', color: '#9966ff', mx: 2}}
                            >
                                <RestartAltIcon/>
                                {dictionary.reset}
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSave}
                                sx={{
                                    backgroundColor: '#9966ff',
                                    borderColor: '#9966ff',
                                    color: 'white',
                                }}
                            >
                                <InfoOutlinedIcon sx={{mr: '4px'}}/>
                                {dictionary.calculate}
                            </Button>
                        </Grid>
                    </AccordionDetails>
                </Accordion>

                {loading && <Loading/>}

                {error &&
                    <Stack sx={{width: '100%'}} spacing={2}>
                        <Alert severity="error">{dictionary.error}</Alert>
                    </Stack>
                }

                {!loading && recommendations.length > 0 && (
                    <>
                        <Typography
                            variant={'h4'}
                            sx={{
                                color: theme.palette.primary.main,
                                mt: 5,
                                fontWeight: 'bold',
                                borderBottom: '1px solid #9966ff',
                            }}
                        >
                            {dictionary.recommendations}
                        </Typography>
                        <Grid container spacing={2} my={1}>
                            {recommendations
                                .filter((recommendation) => recommendation.value === 'True')
                                .map((recommendation) => (
                                    <Grid
                                        item
                                        key={recommendation.id}
                                        xs={12}
                                        md={calculateColumnCount(recommendations.filter((rec) => rec.value === 'True').length)}
                                    >
                                        <Card
                                            sx={{
                                                backgroundColor: 'white',
                                                height: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    flex: 1,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <Typography
                                                    variant="h6"
                                                    align={'center'}
                                                    component="div"
                                                    fontWeight={'bold'}
                                                    sx={{
                                                        marginTop: 0,
                                                        alignSelf: 'flex-start',
                                                        padding: '8px',
                                                        mx: 'auto',
                                                        color: theme.palette.primary.main,
                                                    }}
                                                >
                                                    {recommendation.title}
                                                </Typography>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <img
                                                        src={`/images/service1/id${recommendation.id}.jpg`}
                                                        alt={recommendation.title}
                                                        style={{
                                                            marginTop: '20px',
                                                            maxHeight: '140px',
                                                            width: 'auto',
                                                        }}
                                                    />
                                                    <Typography
                                                        variant="body2"
                                                        color="text.secondary"
                                                        sx={{p: 2}}
                                                    >
                                                        {recommendation.description}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </Card>
                                    </Grid>
                                ))}
                        </Grid>
                    </>
                )}
            </Container>}
        </>
    );
}

export default BuildingRetrofitting;
