import React, {useState, useEffect, useContext} from 'react';
import {Link, useLocation} from "react-router-dom";
import {useKeycloak} from "@react-keycloak/web";
import {LanguageContext} from "../../context/LanguageContext";
import {multilingual} from "../../multilingual";

import {styled, useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import StorefrontIcon from '@mui/icons-material/Storefront';

import FooterContent from "./FooterContent";
import MenuButton from "./MenuButton";

import {appbarMenuButtonItemsEnglish, appbarMenuButtonItemsEnglishLatvian} from "../../appbarMenuButtonItems";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";

const drawerWidth = 270;

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    background: theme.palette.barBackground.main,
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        flexGrow: 1,
        // padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const Footer = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    background: theme.palette.barBackground.main,
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export default function Layout({children}) {
    const {keycloak, initialized} = useKeycloak();

    const location = useLocation()
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const {language, changeLanguage} = useContext(LanguageContext);
    const [dictionary, setDictionary] = useState(multilingual.english.layout);
    const [menu, setMenu] = useState([]);

    useEffect(() => {
        if (multilingual[language]?.layout) {
            setDictionary(multilingual[language].layout);
        }
    }, [language]);


    useEffect(() => {
        const menuItems = [
            { text: dictionary.homepage, icon: <HomeOutlinedIcon sx={{ color: theme.palette.primary.main }} />, path: "/" },
        ];

        if (initialized) {
            if (keycloak.authenticated) {
                menuItems.push(
                    { text: dictionary.menuItem1, icon: <EnergySavingsLeafIcon sx={{ color: theme.palette.primary.main }} />, path: "/building-retrofitting" },
                    { text: dictionary.menuItem2, icon: <SolarPowerIcon sx={{ color: theme.palette.primary.main }} />, path: "/photovoltaic-installation" }
                );
            } else {
                menuItems.push(
                    // { text: dictionary.signUp, icon: <PersonAddIcon sx={{ color: theme.palette.primary.main }} />, path: "/signup" },
                    { text: dictionary.signIn, icon: <AccountCircleIcon sx={{ color: theme.palette.primary.main }} />, path: "#", action: () => keycloak.login() }
                );
            }
        }
        setMenu(menuItems);
    }, [initialized, keycloak, dictionary, language]);

    useEffect(() => {
        if (language === 'en') {
            setDictionary(multilingual.english.layout)
        }
        if (language === 'lat') {
            setDictionary(multilingual.latvian.layout)
        }
    }, [language])

    const handleChange = e => {
        changeLanguage(e.target.value);
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const menuItems = [
        {
            text: dictionary.homepage,
            icon:
                <HomeOutlinedIcon
                    sx={{color: theme.palette.primary.main}}/>,
            path: "/",
        },
    ]

    useEffect(() => {
        if (initialized) {
            if (keycloak.authenticated) {
                menuItems.push(
                    {
                        text: dictionary.menuItem1,
                        icon:
                            <EnergySavingsLeafIcon
                                sx={{color: theme.palette.primary.main}}/>,
                        path: "/building-retrofitting",
                    },
                    {
                        text: dictionary.menuItem2,
                        icon:
                            <SolarPowerIcon
                                sx={{color: theme.palette.primary.main}}/>,
                        path: "/photovoltaic-installation",
                    },
                )
            } else {
                menuItems.push(
                    // {
                    //     text: dictionary.signUp,
                    //     icon:
                    //         <PersonAddIcon
                    //             sx={{color: theme.palette.primary.main}}/>,
                    //     path: "/signup",
                    // },
                    {
                        text: dictionary.signIn,
                        icon:
                            <AccountCircleIcon
                                sx={{color: theme.palette.primary.main}}/>,
                        // path: "/signup",
                    },
                )
            }

            setMenu(menuItems)
        }
    }, [initialized, keycloak])

    const handleSignOut = () => {
        keycloak.logout({ redirectUri: window.location.origin });
        setMenu(menuItems);
    };


    return (
        <>
            <Box sx={{display: 'flex', minHeight: `calc(100vh - 60px)`}}>
                <CssBaseline/>
                <AppBar position="fixed" open={open}>
                    <Toolbar>
                        <IconButton
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: 5,
                                ...(open && {display: 'none'}), color: 'white'
                            }}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Typography variant="h6" noWrap component="div" color={'white'}>
                            {dictionary.platformTitle}
                        </Typography>
                        <FormControl sx={{ml: 'auto', minWidth: 120}} size="small" className={'language'}>
                            <InputLabel
                                id="demo-select-small-label"
                                sx={{
                                    color: 'white',
                                    '&.Mui-focused': {
                                        color: 'white', // Ensures it stays white when focused
                                    },
                                }}
                            >
                                {dictionary.language}
                            </InputLabel>
                            <Select
                                sx={{
                                    color: "white",
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(255, 255, 255, 1)',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(255, 255, 255, 1)',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(255, 255, 255, 1)',
                                    },
                                    '.MuiSvgIcon-root ': {
                                        fill: "white !important",
                                    }
                                }}
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={language}
                                label={dictionary.language}
                                onChange={handleChange}
                            >
                                <MenuItem value={'en'}>English</MenuItem>
                                <MenuItem value={'lat'}>Latvian</MenuItem>
                            </Select>
                        </FormControl>
                        {keycloak.authenticated === true && <>
                            <Typography
                                sx={{ml: 2}}
                                style={{
                                    color: 'white'
                                }}>{dictionary.welcome}, {keycloak?.tokenParsed?.preferred_username}</Typography>
                            <MenuButton subLinks={language === 'en' ? appbarMenuButtonItemsEnglish : appbarMenuButtonItemsEnglishLatvian} signout={handleSignOut}/>
                        </>
                        }
                    </Toolbar>
                </AppBar>
                <Drawer
                    sx={{
                        width: drawerWidth,
                        // background: '#fafafa',
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant="persistent"
                    anchor="left"
                    open={open}
                >
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                        </IconButton>
                    </DrawerHeader>
                    <Divider/>
                    <List>
                        {menu.map((item, index) => (
                            <ListItem key={index} disablePadding>
                                {item.text === dictionary.signIn ? (
                                    <ListItemButton
                                        component="button"
                                        onClick={() => keycloak.login()}
                                        sx={{
                                            background: location.pathname === item.path ? 'linear-gradient(to left, rgba(153,102,255,1), rgba(24,229,176,1) 100%)' : '',
                                            border: location.pathname === item.path ? '1px solid rgba(153,102,255,1)' : '',
                                            borderRadius: '10px', marginX: 1, width: '95%'
                                        }}>
                                        <ListItemIcon>{item.icon}</ListItemIcon>
                                        <ListItemText sx={{ color: location.pathname === item.path ? 'white' : '' }} primary={item.text} />
                                    </ListItemButton>
                                ) : (
                                    <ListItemButton
                                        component={item.path !== "#" ? Link : "button"}
                                        to={item.path !== "#" ? item.path : undefined}
                                        onClick={item.action || undefined}
                                        sx={{
                                            background: location.pathname === item.path ? 'linear-gradient(to left, rgba(153,102,255,1), rgba(24,229,176,1) 100%)' : '',
                                            border: location.pathname === item.path ? '1px solid rgba(153,102,255,1)' : '',
                                            borderRadius: '10px', marginX: 1, width: '95%'
                                        }}>
                                        <ListItemIcon>{item.icon}</ListItemIcon>
                                        <ListItemText sx={{ color: location.pathname === item.path ? 'white' : '' }} primary={item.text} />
                                    </ListItemButton>
                                )}
                            </ListItem>
                        ))}
                    </List>

                    <Divider/>

                    {keycloak.authenticated === true && <>
                        <List>
                            <a href="https://train-app.ai4ef.enershare1.epu.ntua.gr/overview/activity/timeline"
                               style={{
                                   textDecoration: 'none',
                                   color: '#000',
                                   width: '100%'
                               }}
                               target="_blank"
                               rel="noopener noreferrer"
                            >
                                <ListItem disablePadding
                                          sx={{
                                              borderRadius: '10px', marginX: 1, width: '95%'
                                          }}>
                                    <ListItemButton>
                                        <ListItemIcon className={'menuIcon'}>
                                            <ModelTrainingIcon sx={{color: theme.palette.primary.main}}/>
                                        </ListItemIcon>
                                        <ListItemText primary={dictionary.menuItem3}/>
                                    </ListItemButton>
                                </ListItem>
                            </a>
                        </List>

                        <Divider/>

                        <List>
                            <a href="https://enershare.zapto.org:4173/"
                               style={{
                                   textDecoration: 'none',
                                   color: '#000',
                                   width: '100%'
                               }}
                               target="_blank"
                               rel="noopener noreferrer"
                            >
                                <ListItem disablePadding
                                          sx={{
                                              borderRadius: '10px', marginX: 1, width: '95%'
                                          }}>
                                    <ListItemButton>
                                        <ListItemIcon className={'menuIcon'}>
                                            <StorefrontIcon sx={{color: theme.palette.primary.main}}/>
                                        </ListItemIcon>
                                        <ListItemText primary={dictionary.menuItem4}/>
                                    </ListItemButton>
                                </ListItem>
                            </a>
                        </List>

                        <Divider/>

                        <List>
                            <ListItem disablePadding
                                      onClick={handleSignOut}
                                      sx={{
                                          borderRadius: '10px', marginX: 1, width: '95%',
                                      }}>
                                <ListItemButton>
                                    <ListItemIcon className={'menuIcon'}>
                                        <LogoutOutlinedIcon sx={{color: theme.palette.primary.main}}/>
                                    </ListItemIcon>
                                    <ListItemText primary={dictionary.singOut}/>
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </>}

                </Drawer>
                <Box component="main" sx={{flexGrow: 1, flex: 1}}>
                    <Main open={open}>
                        <DrawerHeader/>
                        {children}
                    </Main>
                </Box>
            </Box>
            <Footer open={open} sx={{position: 'sticky', mt: 'auto', mb: '-25px'}}><FooterContent/></Footer>
        </>
    );
}
